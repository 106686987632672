.new-bottom-form {
  $new-bottom-form: &;

  padding: 35px 0 40px;
  background: #052c58 !important;
  @include media-query(992px) {
    // background-image: url(/images/new-bottom-form/bg-graphic1.png) !important;
    background-color: #052c58 !important;
    background-repeat: no-repeat !important;
    background-position: 0 0;
    padding: 0 0 40px;
  }
  .container {
    padding: 0 15px;
  }
  &__wrapper {
    position: relative;
    @include media-query(992px) {
      display: flex;
      // gap: 0 90px;
    }
  }
  &__left {
    position: relative;
    z-index: 99;
    margin-right: 0;
    @include media-query(992px) {
      padding-top: 60px;
      flex: 0 0 58%;
      margin-right: 45px;
    }
    @include media-query(1180px) {
      margin-right: 90px;
    }
    .attachment-list {
      width: 100%;
      @include media-query(992px) {
        width: 99%;
        display: flex;
        flex-wrap: wrap;
        gap: 0 10px;
      }
      li {
        @include media-query(992px) {
          flex: 0 0 49%;
          max-width: 49% !important;
        }
      }
    }
  }
  &__right {
    position: relative;
    display: inline-block;
    @include media-query(992px) {
      display: inherit;
      flex: 1;
    }
    // &::before {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     right: -100px;
    //     width: 765px;
    //     height: 740px;
    //     background: url(/images/new-bottom-form/bg-graphic2.png) no-repeat center center;
    //     background-size: cover;
    // }
  }
  h2 {
    font-family: "Poppins";
    font-size: 31px;
    line-height: 38px;
    font-weight: 700;
    text-align: center;
    @include media-query(992px) {
      text-align: left;
    }
  }
  .form-sub-heading {
    font-family: "Poppins";
    padding: 0;
    color: #fff;
    font-size: 25px;
    line-height: 30px;
    font-weight: 600;
    margin: 0 0 25px;
    @include media-query(992px) {
      color: #75a3f0;
      margin: 15px 0 25px;
    }
  }
  &__strong {
    color: #bad2ed;
  }
  .o-contact-form__contact-form {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    // gap: 0 20px;
    justify-content: space-between;
  }
  .form-panel {
    width: 100%;
    padding: 0;
    background: none;
  }
  input {
    font-family: "Poppins";
    background: none;
    border: 1px solid #98bcf8;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 500;
    padding: 15px 15px;
    margin-bottom: 15px;
    color: #fff;
    flex: 0 0 100%;
    @include media-query(992px) {
      flex: 0 0 48.5%;
      margin-bottom: 20px;
      padding: 20px 15px;
    }
    &::placeholder {
      font-size: 17px;
      font-weight: 500 !important;
      color: #fff;
    }
    &[type="number"] {
      -moz-appearance: textfield;
      -webkit-appearance: none;
      appearance: none;
    }
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  textarea {
    font-family: "Poppins";
    flex: 0 0 100%;
    border: 1px solid #98bcf8;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 500;
    background: none !important;
    padding: 25px 15px;
    height: 120px;
    margin-bottom: 15px;
    color: #fff;
    @include media-query(992px) {
      flex: 0 0 100%;
      height: 150px;
    }
    &::placeholder {
      font-size: 17px;
      font-weight: 500 !important;
      color: #fff;
    }
  }
  .attach {
    background-position: 0 15px;
    flex: 0 0 100%;
    @include media-query(767px) {
      background-position: 0 0;
      padding: 0px 20px 0 40px;
      margin-bottom: 20px;
      margin-top: 10px;
    }
  }
  .o-contact-form__attachement-text {
    font-family: "Poppins";
    color: #75a3f0;
    font-size: 16px;
  }
  label {
    color: #fff;
  }
  &__btn-wrapper {
    margin-top: 15px;
    margin-bottom: 0;
    flex-direction: column-reverse;
    width: 100%;
    display: flex;
    @include media-query(992px) {
      margin-top: 10px;
      margin-bottom: 50px;
      flex: 0 0 100%;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-end;
      width: inherit;
    }
    button {
      font-family: "Poppins";
      margin: 0 auto !important;
      padding: 19px 0;
      font-size: 17px;
      font-weight: 600;
      width: 360px;
      text-transform: capitalize;
      @include media-query(992px) {
        width: 280px;
        margin: 0 !important;
      }
    }
  }
  .confidential-text-wrap {
    flex: 1;
    text-align: center;
    margin-top: 0;
    margin-bottom: 20px;
    @include media-query(992px) {
      text-align: left;
      margin-top: 0;
      margin-bottom: 0;
    }
    ul {
      li {
        font-family: "Poppins";
        font-size: 16px;
        font-weight: 400;
        color: #fff;
        padding: 0 15px;
        &:first-child {
          padding-left: 0;
        }
        &::after {
          background: #215894;
          height: 16px;
          top: 4px;
        }
        img {
          width: 17px;
          height: 17px;
        }
      }
    }
  }
  &__whats-next {
    padding-top: 35px;
    border-top: 1px solid #215894;
    margin-top: 45px;
    @include media-query(992px) {
      margin-top: 0;
    }
    h3 {
      font-family: "Poppins";
      font-size: 31px;
      font-weight: 700;
      color: #fff;
    }
    p {
      font-family: "Poppins";
      font-size: 21px;
      font-weight: 600;
      color: #4492dc;
    }
  }
  .form-right {
    flex: 0 0 60%;
  }
  .form-left {
    flex: 0 0 100%;
    width: 100%;
    padding: 0;
    position: relative;
    background-color: transparent;
  }
  &__email {
    text-align: center;
    background: #135195;
    padding: 60px 40px 60px;
    img {
      margin-bottom: 25px;
    }
    h4 {
      font-family: "Poppins";
      font-size: 22px;
      line-height: 30px;
      font-weight: 600;
      color: #bad2ed;
      a {
        font-weight: 700;
        color: #f3f6f9;
        position: relative;
        &:before {
          content: "";
          background: #5289c7;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
        }
      }
    }
  }
  &__trust {
    background: #04254a;
    padding: 50px 40px 40px;
    h5 {
      font-family: "Poppins";
      font-size: 22px;
      line-height: 28px;
      font-weight: 700;
      color: #f3f6f9;
      margin-bottom: 25px;
      span {
        color: #bad2ed;
        font-weight: 400;
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 0;
      padding-bottom: 20px;
      margin-bottom: 20px;
      gap: 15px;
      li {
        flex: 0 0 31%;
        margin-right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        @include media-query(992px) {
          flex: 0 0 47%;
          justify-content: flex-start;
        }
        img {
          filter: none;
        }
        &:nth-of-type(odd) {
          @include media-query(992px) {
            justify-content: flex-start;
          }
        }
        &:nth-of-type(even) {
          @include media-query(992px) {
            justify-content: flex-end;
          }
        }
        &:nth-child(3) {
          margin-top: 15px;
        }
        &:nth-child(4) {
          margin-top: 15px;
          @include media-query(992px) {
            align-items: flex-start;
          }
        }
        &:nth-child(2) {
          justify-content: center;
        }
        &:nth-child(6) {
          justify-content: center;
        }
      }
    }
    h6 {
      font-family: "Poppins";
      color: #75a3f0;
      font-size: 15px;
      font-weight: 500;
    }
  }
}

.skill-bottom-form-custom-dev {
  h2 {
    font-weight: 500;
  }
}

.skill-bottom-form__strong {
  color: #cddefa;
}